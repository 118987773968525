export const Manifest = [
  {
    name: 'Home',
    path: '/#',
  },
  {
    name: 'How it works',
    path: '/#how-it-works',
  },
  {
    name: 'Destinations',
    path: '/destinations/',
  },
  {
    name: 'Reviews',
    path: '/#reviews',
  },
  {
    name: 'Pricing',
    path: '/pricing/',
  },
  {
    name: 'Journal',
    path: '/journal/',
  },
]
